import { Button, Loading } from "@nextui-org/react";
import { useState } from "react";
import { TbFileExport, TbFileInvoice } from "react-icons/tb";
import * as Xlsx from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

const ExportInventory = ({ currentList, alt }) => {
  const [exportLoading, setExportLoading] = useState(false);

  const downloadExcel = () => {
    setExportLoading(true);

    let strucuredData = [];
    currentList.iphones?.forEach((doc) => {
      doc?.variants?.forEach((d) => {
        strucuredData.push({
          name: doc.name,
          pid: doc?.pid,
          price: d?.price,
          qty: d?.quantity,
          variant: d?.storage,
        });
      });
    });

    currentList.ipods?.forEach((doc) => {
      strucuredData.push({
        name: doc.name,
        pid: doc?.pid,
        price: doc?.price,
        qty: doc?.quantity,
        variant: "Default",
      });
    });

    currentList.iwatches?.forEach((doc) => {
      doc?.variants?.forEach((d) => {
        strucuredData.push({
          name: doc.name,
          pid: doc?.pid,
          price: d?.price,
          qty: d?.quantity,
          variant: `${d?.size} ${d?.type}`,
        });
      });
    });

    const data = strucuredData
      .sort((a, b) => {
        return ("" + a.name).localeCompare(b.name);
      })
      .map((doc) => {
        return {
          Name: doc.name,
          Variant: doc?.variant,
          ID: doc?.pid,
          Price: doc?.price,
          Qty: doc?.qty,
        };
      });
    const workSheet = Xlsx.utils.json_to_sheet(data);
    const workBook = Xlsx.utils.book_new();
    Xlsx.utils.book_append_sheet(workBook, workSheet, "Sheet1");
    Xlsx.writeFile(workBook, `${alt ? "HOI" : "Inventory"}.xlsx`);
    setExportLoading(false);
  };

  const downloadExcel2 = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const title = `${
      alt ? "HOI" : "Inventory"
    } ${new Date().toLocaleDateString()}`;

    const headers = [
      "Product",
      "Variant 1",
      "Variant 2",
      "Variant 3",
      "Variant 4",
      "Variant 5",
    ];

    let strucuredData = [];

    currentList.iphones?.forEach((doc) => {
      let varDoc = {
        name: doc?.name,
        var1: "",
        var2: "",
        var3: "",
        var4: "",
        var5: "",
      };

      const isValid = doc?.variants?.filter((d) => d.quantity !== 0);
      if (isValid?.length > 0) {
        isValid?.forEach((d, i) => {
          varDoc[`var${i + 1}`] = `${d?.storage} = ${d?.quantity}`;
        });
        strucuredData.push(varDoc);
      }
    });

    currentList.ipods?.forEach((doc) => {
      let varDoc = {
        name: doc?.name,
        var1: "",
        var2: "",
        var3: "",
        var4: "",
        var5: "",
      };

      const isValid = doc?.quantity !== 0;
      if (isValid) {
        varDoc.var1 = `Default = ${doc?.quantity}`;
        strucuredData.push(varDoc);
      }
    });

    currentList.iwatches?.forEach((doc) => {
      let varDoc = {
        name: doc?.name,
        var1: "",
        var2: "",
        var3: "",
        var4: "",
        var5: "",
      };

      const isValid = doc?.variants?.filter((d) => d.quantity !== 0);
      if (isValid?.length > 0) {
        isValid?.forEach((d, i) => {
          varDoc[`var${i + 1}`] = `${d?.size} ${d.type} = ${d?.quantity}`;
        });
        strucuredData.push(varDoc);
      }
    });

    // Determine active columns
    const activeColumns = headers.slice(1).map((_, colIndex) => {
      return strucuredData.some((row) => row[`var${colIndex + 1}`] !== "");
    });

    // Filter headers and data based on active columns
    const filteredHeaders = ["Product"].concat(
      headers.slice(1).filter((_, index) => activeColumns[index])
    );

    const data = strucuredData
      .sort((a, b) => {
        return ("" + a.name).localeCompare(b.name);
      })
      .map((doc) => {
        return [doc.name]
          .concat(
            Array(5)
              .fill("")
              .map((_, index) =>
                activeColumns[index] ? doc[`var${index + 1}`] : undefined
              )
          )
          .filter((val) => val !== undefined);
      });

    let content = {
      startY: 50,
      head: [filteredHeaders],
      body: data,
      headStyles: {
        fillColor: [0, 0, 0], // Black background
        textColor: [255, 255, 255], // White text
        fontStyle: "bold", // Bold text
      },
      bodyStyles: {
        fontStyle: "bold", // Bold text for body
      },
      didDrawCell: (data) => {
        const text = data.cell.raw?.toString().trim(); // Ensure raw data is string and trimmed

        if (text) {
          // Regex to extract the numeric part after '='
          const match = text.match(/=\s*(-?\d+)/);
          const value = match ? parseInt(match[1], 10) : null;

          if (value !== null && value < 0) {
            // Fill the background with light red
            doc.setFillColor(255, 200, 200); // Light red
            doc.rect(
              data.cell.x,
              data.cell.y,
              data.cell.width,
              data.cell.height,
              "F"
            );

            // Redraw the text (ensures it stays visible)
            doc.setTextColor(0, 0, 0); // Black text
            doc.text(data.cell.text, data.cell.x + 6, data.cell.y + 6, {
              baseline: "top",
            });

            // Redraw the border
            doc.setDrawColor(142, 134, 134); // Border color
            doc.setLineWidth(0.5); // Border width
            doc.rect(
              data.cell.x,
              data.cell.y,
              data.cell.width,
              data.cell.height
            );
          } else {
            // Draw borders for each cell
            doc.setDrawColor(142, 134, 134); // RGB color for #8e8686
            doc.setLineWidth(0.5); // Border width
            doc.rect(
              data.cell.x,
              data.cell.y,
              data.cell.width,
              data.cell.height
            );
          }
        } else {
          // Draw borders for each cell
          doc.setDrawColor(142, 134, 134); // RGB color for #8e8686
          doc.setLineWidth(0.5); // Border width
          doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
        }
      },
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(
      alt ? "HOI " : "Inventory " + new Date().toLocaleDateString() + ".pdf"
    );
  };

  return (
    <>
      <Button
        auto
        size="sm"
        color="primary"
        icon={
          exportLoading ? (
            <Loading type="spinner" color="white" />
          ) : (
            <TbFileInvoice />
          )
        }
        onClick={downloadExcel2}
        css={{ mr: 3 }}
      />
      <Button
        auto
        size="sm"
        color="success"
        icon={
          exportLoading ? (
            <Loading type="spinner" color="white" />
          ) : (
            <TbFileExport />
          )
        }
        onClick={downloadExcel}
        css={{ mr: 3 }}
      />
    </>
  );
};

export default ExportInventory;

import { Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import Home from "./pages/Home";

import "./App.css";
import "./pages/Styles.css";
import Inventory from "./pages/Inventory";
import Report from "./pages/Report";
import Details from "./pages/Details";
import Charts from "./pages/Charts";
import ReportDate from "./pages/ReportDate";
import TableReport from "./pages/TableReport";
import ProductsReport from "./pages/Product";
import Auth from "./pages/Auth";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { invtryActions } from "./redux/inventorySlice";
import DailyPdf from "./pages/daily-pdf";
import Calculator from "./pages/calculator";
import AddProduct from "./pages/products/add";
import Product from "./pages/products";
import EditProduct from "./pages/products/edit";
import Udhar from "./pages/udhar";
import UdharDetails from "./pages/UdharDetails";
import ManageItemOrder from "./pages/order";
import OrderDetails from "./pages/OrderDetails";

function App() {
  const isAuth = useSelector((state) => state.invtry.isAuth);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("route", location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    if (!isAuth) {
      const token = localStorage.getItem("token") || "";
      const level = localStorage.getItem("level") || "";
      console.log(token);

      if (token !== "" && level !== "") {
        dispatch(invtryActions.setAuth({ token, level }));
      }
    }
  }, []);

  useEffect(() => {
    const lastPath = localStorage.getItem("route");
    if (
      lastPath.includes("/products-report") ||
      lastPath.includes("/table-report")
    ) {
      navigate("/report");
    } else {
      navigate(lastPath);
    }
  }, [isAuth]);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={isAuth ? <Home /> : <Navigate to="/auth" />} />
        <Route
          path="/auth"
          element={!isAuth ? <Auth /> : <Navigate to="/" />}
        />
        <Route
          path="/inventory"
          element={isAuth ? <Inventory /> : <Navigate to="/auth" />}
        />
          <Route
          path="/inventory2"
          element={isAuth ? <Inventory alt /> : <Navigate to="/auth" />}
        />
        <Route
          path="/report"
          element={isAuth ? <Report /> : <Navigate to="/auth" />}
        />
        <Route
          path="/details"
          element={isAuth ? <Details /> : <Navigate to="/auth" />}
        />
        <Route
          path="/udhars"
          element={isAuth ? <Udhar /> : <Navigate to="/auth" />}
        />
        <Route
          path="/udhar/details/:billno"
          element={isAuth ? <UdharDetails /> : <Navigate to="/auth" />}
        />
        <Route
          path="/order/details/:billno"
          element={isAuth ? <OrderDetails /> : <Navigate to="/auth" />}
        />
        <Route
          path="/inventory-history"
          element={isAuth ? <Charts /> : <Navigate to="/auth" />}
        />
        <Route
          path="/inventory-history2"
          element={isAuth ? <Charts alt /> : <Navigate to="/auth" />}
        />
        <Route
          path="/report-date/:date"
          element={isAuth ? <ReportDate /> : <Navigate to="/auth" />}
        />
        <Route
          path="/table-report"
          element={isAuth ? <TableReport /> : <Navigate to="/auth" />}
        />
        <Route
          path="/products-report"
          element={isAuth ? <ProductsReport /> : <Navigate to="/auth" />}
        />
        <Route
          path="/pdfs"
          element={isAuth ? <DailyPdf /> : <Navigate to="/auth" />}
        />
        <Route
          path="/calculator"
          element={isAuth ? <Calculator /> : <Navigate to="/auth" />}
        />
        <Route
          path="/add"
          element={isAuth ? <AddProduct /> : <Navigate to="/auth" />}
        />
        <Route
          path="/manage-item"
          element={isAuth ? <ManageItemOrder /> : <Navigate to="/auth" />}
        />
        <Route
          path="/view"
          element={isAuth ? <Product /> : <Navigate to="/auth" />}
        />
        <Route
          path="/edit/:id"
          element={isAuth ? <EditProduct /> : <Navigate to="/auth" />}
        />
      </Routes>
    </div>
  );
}

export default App;

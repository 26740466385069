import { Grid, Input, Text } from "@nextui-org/react";

const Inputs = ({ name, link, desc }) => {
  return (
    <>
      <Grid xs={12} md={6}>
        <Input
          fullWidth
          value={name.inputValue}
          onChange={name.inputHandler}
          onBlur={name.blurHandler}
          placeholder="Product Name"
          bordered
        />
      </Grid>
      {/* <Grid xs={12} md={4}>
        <Input
          fullWidth
          value={link.inputValue}
          onChange={link.inputHandler}
          onBlur={link.blurHandler}
          placeholder="Link"
          bordered
        />
      </Grid>
      <Grid xs={12}>
        <Input
          fullWidth
          value={desc.inputValue}
          onChange={desc.inputHandler}
          onBlur={desc.blurHandler}
          placeholder="Short Description"
          bordered
        />
      </Grid> */}
    </>
  );
};

export default Inputs;
